<template>
  <div class="driver" v-loading.fullscreen.lock="fullscreenLoading" element-loading-background="rgba(0, 0, 0, 0.8)">
    <div id="driver_map">
      <div class="tabs">
         <div class="ele" v-for="(item,index) in tabs" :key="index" :class="{active: item.val == tab_avtive}" @click="changeTab(item)">
          <div v-if="item.val == 0 && tab_avtive == 0 && diyTime && diyTime.length > 0">
            <span>{{ diyTime[0] }}</span>
            <span>{{ diyTime[1] }}</span>
          </div>
          <div v-else>{{item.name}}</div>
        </div>
      </div>
    </div>
    <el-drawer
      title="自定义时间区间"
      :visible.sync="drawer"
      direction="ltr"
      :size="420">
      <el-date-picker
        v-model="diyTime"
        type="daterange"
        range-separator="到"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        @change="selectTime"
        :picker-options="pickerOptions">
      </el-date-picker>
      <el-button type="primary" @click="selectTime(diyTime)">确定</el-button>
    </el-drawer>
    <div class="card-box" :class="{'open-data':open}">
      <div class="ele">
        <card class="card">
          <div class="title">车辆状态</div>
          <div class="tag-box" v-if="info">
            <div class="tag">
              <span class="key">车辆工作时长/时</span>
              <span class="val">{{ info.working_hours }}</span>
            </div>
            <div class="tag">
              <span class="key">车辆休息时长/时</span>
              <span class="val">{{ info.rest_hours }}</span>
            </div>
          </div>
        </card>
      </div>
      <div class="ele">
        <card class="card">
          <div class="title">到达过的矿山</div>
          <div class="scroll-box">
            <vue-scroll>
              <div class="list-box">
                <div class="list" v-for="(item,index) in mine_list" :key="index">
                  <div class="list-bar">{{ index >= 9 ? index+1 : '0' + (index+1) }}</div>
                  <div class="list-desc" v-if="item">
                    <div class="list-desc-left" v-if="item.name" :title="item.name">{{ item.name }}</div>
                    <!-- <div class="list-desc-right" v-if="item.count">{{ item.count + '次' }}</div> -->
                  </div>
                </div>
              </div>
            </vue-scroll>
          </div>
        </card>
      </div>
      <div class="ele">
        <card class="card">
          <div class="title">到达过的商砼站</div>
          <div class="scroll-box">
            <vue-scroll>
              <div class="list-box">
                <div class="list" v-for="(item,index) in  conc_list" :key="index">
                  <div class="list-bar">{{ index >= 9 ? index+1 : '0' + (index+1) }}</div>
                  <div class="list-desc" v-if="item">
                    <div class="list-desc-left" v-if="item.name" :title="item.name">{{ item.name }}</div>
                    <!-- <div class="list-desc-right" v-if="item.count">{{ item.count + '次' }}</div> -->
                  </div>
                </div>
              </div>
            </vue-scroll>
          </div>
        </card>
      </div>
      <div class="ele">
        <card class="card">
          <div class="title">车牌号</div>
          <div class="tag-box">
            <div class="tag">
              <span class="key">车辆牌照</span>
              <span class="val" :title="$route.params.id">{{ $route.params.id ? $route.params.id : '暂无数据' }}</span>
            </div>
          </div>
          <div class="title">关联司机</div>
          <div class="driver-box">
            <vue-scroll>
              <div class="tag-box">
                <div class="tag" v-for="(item,index) in diverList" :key="index">
                  <span class="key">{{ item.nickname }}</span>
                  <span class="val">{{ item.mobile }}</span>
                </div>
              </div>
            </vue-scroll>
          </div>
        </card>
      </div>
      <div class="open" @click="open = !open">
        <div class="img-box" :class="{rotate:open}">
          <img src="@img/open.png">
        </div>
      </div>
    </div>
    <info-window ref="infoWindow"></info-window>
  </div>
</template>
<script>
import { lazyAMapApiLoaderInstance } from "vue-amap";
import icons from '../../assets/js/map_icon.js';
export default {
  name:"driver",
  data(){
    return {
      drawer:false,
      diyTime:[],
      pickerOptions:{
        disabledDate(time){
          return time.getTime() > Date.now();
        }
      },
      fullscreenLoading:false,
      id:null,
      tabs:[{name:'自定义',val:0},{name:'三天',val:3},{name:'一周',val:7},{name:'半个月',val:15},{name:'一个月',val:30}],
      tab_avtive:30,
      diverList:[],
      info:null,
      mine_list: [],
      conc_list: [],
      open:false,
    }
  },
  created(){
    this.id = this.$route.params.id;
    lazyAMapApiLoaderInstance.load().then(()=>{
      this.map = new AMap.Map("driver_map", {
        expandZoomRange: true,
        zooms: [5, 18],
        zoom: 10,
        mapStyle: "amap://styles/ddf1c576d07f43d8ad696a72ca5543a5",
      });
      AMap.plugin(['AMap.Scale',], ()=>{this.map.addControl(new AMap.Scale())});
      this.getDriverList();
      this.getCarDetails(this.getDateStr(this.tab_avtive)[0],this.getDateStr(this.tab_avtive)[1])
      this.getCarOrderList(this.getDateStr(this.tab_avtive)[0],this.getDateStr(this.tab_avtive)[1])
      this.map.on('click',(ev)=>{
        // this.showPrice = false;
        // this.showDriver = false;
      })
    })
  },
  methods:{
    changeTab(item){
      this.tab_avtive = item.val;
      if(item.val != 0){
        if(this.map){
          this.map.remove(this.overlayGroup);
          this.map.remove(this.map.getAllOverlays('InfoWindow'));
          this.getCarDetails(this.getDateStr(this.tab_avtive)[0],this.getDateStr(this.tab_avtive)[1])
          this.getCarOrderList(this.getDateStr(this.tab_avtive)[0],this.getDateStr(this.tab_avtive)[1])
          // this.showPrice = false;
          // this.showDriver = false;
          // this.getconcreteDetails(this.getDateStr(this.tab_avtive)[0],this.getDateStr(this.tab_avtive)[1]);
        }
      }else{
        this.drawer = true;
      }
    },
    selectTime(time){
      if(time && time.length > 0){
        this.drawer = false;
        this.map.remove(this.overlayGroup);
        this.map.remove(this.map.getAllOverlays('InfoWindow'));
        this.getCarDetails(`${time[0]} 00:00:00`,`${time[1]} 23:59:59`);
        this.getCarOrderList(`${time[0]} 00:00:00`,`${time[1]} 23:59:59`)
        // this.showPrice = false;
        // this.showDriver = false;
        // this.getconcreteDetails(`${time[0]} 00:00:00`,`${time[1]} 23:59:59`);
      }
    },
    getCarDetails(start,end){
      this.$api.getCarDetail({
        car_number: this.id,
        start_time: start,
        end_time: end
      }).then(res => {
        if(res.code == 1){
          this.info = res.data.info
        }
      })
      this.$api.getCarMineList({
        car_number: this.id,
        start_time: start,
        end_time: end,
        page: 1,
        size: 100
      }).then(res => {
        if(res.code == 1){
          this.mine_list = res.data.lists
        }
      })
      this.$api.getCarConlist({
        car_number: this.id,
        start_time: start,
        end_time: end,
        page: 1,
        size: 100
      }).then(res => {
        if(res.code == 1){
          this.conc_list = res.data.lists
        }
      })
    },
    getDriverList(){
      this.$api.getDriverDetails({
        car_number:this.id,
        page:1,
        size:100
      }).then(res => {
        if(res.code == 1){
          this.diverList = res.data.lists
        }
      })
    },
    getCarOrderList(start,end){
      this.fullscreenLoading = true
      let _this = this;
      this.overlayGroup = new AMap.OverlayGroup();
      this.$api.getCarOrderList({
        car_number:this.id,
        page:1,
        size:1000,
        start_time: start,
        end_time: end
      }).then(res => {
        this.fullscreenLoading = false
        if(res.code == 1){
          res.data.lists.forEach(ele => {
            // 矿山窗口
            let infoWindow = new AMap.InfoWindow({
              offset: new AMap.Pixel(0, -50),
              isCustom:true,
              closeWhenClickMap:true,
            });
            // 矿山坐标
            let marker = new AMap.Marker({
              position: [ele.factory_lon, ele.factory_lat],
              icon: icons[`type_3_${ele.factory_level ? (ele.factory_level-1) : 0}`],
              offset: new AMap.Pixel(0, 2),
              anchor:'bottom-center',
              zIndex:300
            });
            marker.on('click', (e)=>{
              _this.$refs.infoWindow.initialize({
                type:1,
                title:ele.factory_name,
                color:'#E9310F',
                showPrice:false,
                showDisable:false,
                showSecond:false,
                ele
              })
              infoWindow.setContent(this.$refs.infoWindow.$el);
              infoWindow.open(_this.map, e.target.getPosition());
            });
            // this.overlayGroup.add(marker)

            // 商砼窗口
            let infoWindowTwo = new AMap.InfoWindow({
              offset: new AMap.Pixel(0, -30),
              isCustom:true,
              closeWhenClickMap:true,
            });
            // 商砼坐标
            let markerTwo = new AMap.Marker({
              position: [ele.commercial_concrete_lon,ele.commercial_concrete_lat],
              icon: icons.type_1_1,
              offset: new AMap.Pixel(0, 6),
              anchor:'bottom-center',
              zIndex:200+ele.landmarkLevel
            });
            markerTwo.on('click', (e)=>{
              _this.$refs.infoWindow.initialize({
                type:3,
                title:ele.commercial_concrete_name,
                color:'#11DB33',
                showPrice:false,
                showDisable:false,
                showSecond:false,
                ele
              })
              infoWindowTwo.setContent(this.$refs.infoWindow.$el);
              infoWindowTwo.open(_this.map, e.target.getPosition());
            });
            // this.overlayGroup.add(markerTwo)
            this.setLine(ele.trajectory)
            // ele.trajectory.forEach(element => {

            // })
          });
        }
        

        this.map.add(this.overlayGroup);
        this.map.setFitView();
      })
    },
    setLine(arr){
      let _this = this
      arr.forEach(element => {
        // 线
        let line = new AMap.Polyline({
          path: element.location,
          strokeColor: "#0F92DA",
          strokeOpacity: 0.9,
          strokeWeight: 6,
          strokeStyle: "solid",
          strokeDasharray: [4, 8],
          lineJoin: 'round',
          lineCap: 'round',
          zIndex: 40,
          showDir:true,
          dirColor:'#ffffff'
        })
        this.overlayGroup.add(line);
        element.stopLocation.forEach(ele=>{
          let type;
          let color;
          switch(ele.type_class){
            case '餐饮':
              type = 1;
              color = '#6441f1'
            break;
            case '服务区':
              type = 2;
              color = '#f82e34'
            break;
            case '公共设施':
              type = 3;
              color = '#faa73d'
            break;
            case '购物服务':
              type = 4;
              color = '#fbdc76'
            break;
            case '家居建材市场':
              type = 5;
              color = '#cc51ae'
            break;
            case '住宿':
              type = 6;
              color = '#f96c64'
            break;
            case '收费站':
              type = 7;
              color = '#4f7bfd'
            break;
            case '公司企业':
              type = 8;
              color = '#35cda7'
            break;
            case '加油站':
              type = 9;
              color = '#2b70ff'
            break;
            default:
              type = 10;
              color = '#868fa2'
            break;
          }
          let marker = new AMap.Marker({
            position: ele.stop_point,
            icon: icons[`dirver_${type}`],
            offset: new AMap.Pixel(0, 2),
            anchor:'bottom-center',
            zIndex:200
          });
          let infoWindow = new AMap.InfoWindow({
            offset: new AMap.Pixel(0, -40),
            isCustom:true,
            closeWhenClickMap:true,
          });
          marker.on('click', (e)=>{
            _this.$refs.infoWindow.initialize({
              color:color,
              num:_this.$route.params.id,
              is_new:true,
              ele
            })
            infoWindow.setContent(this.$refs.infoWindow.$el);
            infoWindow.open(_this.map, e.target.getPosition());
          });
          this.overlayGroup.add(marker);
        })
      })
    },
    getDateStr(val){
      var now = new Date().getTime();
      var half = now - 1000 * 60 * 60 * 24 * val;
      return [`${this.returnDate(half)} 00:00:00`,`${this.returnDate(now)} 23:59:59`]
    },
    returnDate(str){
      let _date = new Date(str)
      let year = _date.getFullYear();
      let month = _date.getMonth() + 1;
      month = month < 10 ? '0' + month : month;
      let day = _date.getDate();
      day = day < 10 ? '0' + day : day;
      return `${year}-${month}-${day}`
    },
  }
}
</script>
<style scoped lang='less'>
.driver{
  width: 100%;
  overflow: hidden;
}
#driver_map{
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.tabs{
  box-sizing: border-box;
  width: 550px;
  position: absolute;
  top: 22px;
  left: 30px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ele{
    width: 92px;
    height: 38px;
    background: rgba(4, 58, 112, 0.5);
    border-radius: 3px;
    border: 1px solid #00AEFF;
    box-shadow:0 0 8px 0 #00AEFF inset;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    color: #7AC5E8;
    font-weight: 400;
    cursor: pointer;
    div{
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    span{
      font-size: 12px;
    }
  }
  .active{
    background: rgba(0, 147, 167, 0.5);
    border: 1px solid #00BFEF;
    box-shadow:0 0 8px 0 #0093A7 inset;
    color: #00F7FF;
  }
}

.card-box{
  box-sizing: border-box;
  width: 100%;
  height: 340px;
  padding: 0 10px 40px;
  position: fixed;
  bottom: -290px;
  left: 0;
  transition: all .5s cubic-bezier(1.0, 0.5, 0.8, 1.0);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: space-between;
  .ele{
    box-sizing: border-box;
    width: 25%;
    min-width: 300px;
    height: 100%;
    padding: 0 30px;
    overflow: hidden;
    .card{
      box-sizing: border-box;
      padding: 18px 15px 0;
      display: flex;
      flex-direction: column;
      .title{
        box-sizing: border-box;
        width: 100%;
        text-align: center;
        font-size: 20px;
        color: #B8E5FA;
        font-weight: 400;
        line-height: 28px;
      }
      .tag-box{
        box-sizing: border-box;
        width: 100%;
        margin-top: 10px;
        box-sizing: border-box;
        .tag{
          box-sizing: border-box;
          width: 100%;
          height: 40px;
          margin-bottom: 10px;
          background: rgba(83, 198, 222, 0.11);
          padding: 0 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .key{
            font-size: 18px;
            color: #FFFFFF;
            font-weight: 400;
          }
          .val{
            flex: 1;
            text-align: right;
            padding-left: 20px;
            font-size: 18px;
            color: #FFFFFF;
            font-weight: 400;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
        }
      }
      .scroll-box{
        box-sizing: border-box;
        height: 225px;
        margin-top: 14px;
      }
    }
  }
  .open{
    width: 60px;
    height: 50px;
    background: rgba(25, 49, 86, 0.85);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: absolute;
    left: 40px;
    top: -60px;
    .img-box{
      width: 30px;
      height: 30px;
      transition: All .5s ease;
    }
    img{
      width: 100%;
      height: 100%;
      animation: jump 1s linear infinite;
    }
    .rotate{
      transform: rotate(180deg);
    }
    @keyframes jump {
      0% {
        transform: translateY(0) ;
      }
      25% {
          transform: translateY(3px);
      }
      50% {
          transform: translateY(6px) scale(1.1, 0.9);
      }
      75% {
          ransform: translateY(3px) ;
      }
      100% {
          transform: translateY(0) ;
      }
    }
  }
  
}

.open-data{
  bottom: 0;
  transition: all .5s ease;
}
.driver-box{
  height: 150px;
}
.list-box{
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .list{
    overflow: hidden;
    box-sizing: border-box;
    width: 100%;
    height: 39px;
    margin-bottom: 8px;
    display: flex;
    background: rgba(83, 198, 222, 0.11);
    .list-bar{
      width: 50px;
      height: 100%;
      background: #747980;
      font-size: 18px;
      color: #FFFFFF;
      font-weight: 500;
      display: flex;
      align-items: center;
      justify-content: center;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    // &:nth-child(1){
    //   .list-bar{
    //     background: #E04236;
    //   }
    // }
    // &:nth-child(2){
    //   .list-bar{
    //     background: #CAC401;
    //   }
    // }
    // &:nth-child(3){
    //   .list-bar{
    //     background: #1C7EF6;
    //   }
    // }
    .list-desc{
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 20px 0 12px;
      overflow: hidden;
      .list-desc-left{
        flex: 1;
        height: 100%;
        line-height: 40px;
        font-size: 18px;
        color: #FFFFFF;
        font-weight: 400;
        margin-right: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .list-desc-right{
        font-size: 18px;
        color: #FFFFFF;
        font-weight: 400;
        line-height: 40px;
      }
    }
  }
}
</style>
<style>
.el-drawer__body{
  padding: 20px;
}
.el-drawer__body .el-button{
  margin-top: 14px;
}
</style>